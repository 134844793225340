<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" :to="`/`" router exact>
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Applications</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-btn class="primary--text mr-3" text @click="loadApplications(false)">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>

        <v-btn
          v-if="project"
          text
          class="primary--text"
          :to="{
            name: 'newApplication',
            params: {
              project: projectId,
              namespace: selectedNamespaceId,
            },
          }"
          router
          exact
          :disabled="project.applicationCount >= project.quota.applications"
        >
          <v-icon class="mr-1">add_box</v-icon>
          New Application
        </v-btn>
      </div>
    </v-layout>

    <v-layout>
      <page-subtitle
        >Create, view and manage your applications here.</page-subtitle
      >
    </v-layout>

    <v-layout>
      <v-alert
        dense
        border="left"
        type="warning"
        outlined
        v-if="project && project.applicationCount >= project.quota.applications"
      >
        You have reached your applications quota. To create new application
        either delete an existing one or contact support to increase your
        project's quota.
      </v-alert>
    </v-layout>

    <v-layout class="mt-2">
      <div class="tbcard">
        <div v-if="!selectedNamespaceId">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">Select Namespace</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center">
                    Use the right side menu to select a namespace.
                  </page-subtitle>
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        <div v-else-if="!loading && applications.length === 0">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">No Applications</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center ma-3">
                    Here you will see your applications and their statuses. View
                    documentation examples to see how to define your app.
                  </page-subtitle>
                </span>
                <v-btn
                  class="mt-3"
                  outlined
                  color="primary"
                  rel="noopener"
                  target="_blank"
                  href="https://docs.synpse.net/applications/deploy"
                >
                  View examples
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-card v-else-if="applications.length > 0" outlined>
          <v-data-table
            :key="tableKey"
            :headers="headers"
            :items="applications"
            :search="e3"
          >
            <template v-slot:[`item.name`]="{ item }">
              <router-link
                :to="{
                  name: 'applicationDetails',
                  params: {
                    project: item.projectId,
                    namespace: item.namespaceId,
                    application: item.id,
                  },
                }"
                >{{ item.name }}</router-link
              >
            </template>

            <template v-slot:[`item.scheduling`]="{ item }">
              {{ item.scheduling.type }}
            </template>

            <template v-slot:[`item.image`]="{ item }">
              <template v-for="(container, i) in item.spec.containers">
                <span :key="i">{{ container.image }}<br /></span>
              </template>
            </template>

            <template v-slot:[`item.ports`]="{ item }">
              <template v-for="(container, i) in item.spec.containers">
                <span :key="i">
                  <span v-for="(port, i) in container.ports" :key="i">
                    {{ port }}
                    <br />
                  </span>
                </span>
              </template>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <v-tooltip top :key="item.id">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.createdAt | ago }}
                  </span>
                </template>
                <span>{{ item.createdAt | date }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.instances`]="{ item }">
              <router-link
                :to="{
                  name: 'applicationAllocations',
                  params: {
                    project: item.projectId,
                    namespace: item.namespaceId,
                    application: item.id,
                  },
                }"
                >{{ item.deploymentStatus.available }}/{{
                  item.deploymentStatus.total
                }}
              </router-link>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu class="items" top left transition="v-slide-y-transition">
                <template v-slot:activator="{ on: on }">
                  <v-btn
                    icon
                    v-on="on"
                    :class="
                      $store.state.theme === 'light' ? 'secondary--text' : ''
                    "
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    :to="{
                      name: 'applicationAllocations',
                      params: {
                        project: item.projectId,
                        namespace: item.namespaceId,
                        application: item.id,
                      },
                    }"
                    router
                  >
                    <v-list-item-title>allocations</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :to="{
                      name: 'applicationDetails',
                      params: {
                        project: item.projectId,
                        namespace: item.namespaceId,
                        application: item.id,
                      },
                    }"
                    router
                  >
                    <v-list-item-title>details</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';

export default {
  components: {
    PageSubtitle,
    PageTitle,
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      headers: [
        { text: 'Name', align: 'left', value: 'name', sortable: true },
        { text: 'Scheduling', value: 'scheduling', align: 'left' },
        { text: 'Images', value: 'image', align: 'left' },
        { text: 'Ports', value: 'ports', align: 'left' },
        { text: 'Device Allocations', value: 'instances', align: 'left' },
        { text: 'Created', value: 'createdAt', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
    }
  },

  watch: {
    selectedNamespaceId() {
      this.loadApplications()
    }
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    selectedNamespaceId() {
      return this.$route.params.namespace
    },
    namespaces() {
      return this.$store.state.namespace.namespaces
    },
    loading() {
      return this.$store.state.application.loading
    },
    applications() {
      return this.$store.state.application.applications
      // return this.$store.getters.getApplicationsForNamespace(this.selectedNamespaceId)
    },
    project() {
      return this.$store.state.project.project
    }
  },

  mounted() {
    this.mounted = true
    this.subscribe()
    // Loading the project to get the quota
    let q = {
      projectId: this.projectId
    }
    this.$store.dispatch('GetProject', q)
    // Load applications
    this.loadApplications(false)
  },

  beforeDestroy() {
    this.$store.dispatch('UnsubscribeFromSSE')
  },

  beforeUnmount() {
    this.$store.dispatch('UnsubscribeFromSSE')
  },

  methods: {
    loadApplications(background = true) {
      let q = {
        project: this.projectId,
        background: background
      }
      this.$store.dispatch('ListNamespaces', q)

      if (this.selectedNamespaceId || this.$route.params.namespace) {
        let appQuery = {
          projectId: this.projectId,
          namespaceId: this.$route.params.namespace
        }
        this.$store.dispatch('ListApplications', appQuery)
      }
    },
    subscribe() {
      let token = this.$store.getters.jwt
      let streamUrl = `/api/projects/${this.projectId}/events?stream=${this.projectId}:applicationStatuses&_t=${token}`

      let q = {
        stream: streamUrl,
        callback: this.loadApplications
      }

      this.$store.dispatch('SubscribeToSSE', q)
    },
  }
}
</script>
